.main_container {
  width: 100%;
  background: #F7F7F7 !important;
}

.custom-sidebar-menu {
  width: 196px;
  position: absolute;
  display: flex;
  z-index: 1;
  float: left;
  background: #2A3F54;
  padding: 0 0;
}

.right-col {
  margin-left: 196px !important;
  padding: 10px 20px 0;
  max-height: 100%;
  overflow: hidden;
}

.row {
  padding-top: 10px;
  margin-right: -10px;
  margin-left: -10px;
}