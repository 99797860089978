.patients-filter-button {
  border-radius: 3px;
  margin-bottom: 5px;
  margin-right: 3px;
  color: #fff;
  background-color: #475f87;
  display: inline-block;
  padding: 6px 12px;
  font-weight: 600;
  font-size: 13px;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
}