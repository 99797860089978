.video {
  position: fixed;
  z-index: -1;
  width: 100%;
}

.login-content {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-input-container {
  width: 40%;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.email-input {
  color: #6EC1E4 !important;
  height: 70px;
  border-radius: 31px 31px 31px 31px !important;
  border: 4px solid #6EC1E4 !important;
  background-color: black;
  width: 100%;
  font-size: 26px;
  font-weight: 300;
  padding-left: 22px;
}

.login-button {
  cursor: pointer;
  line-height: 40px;
  font-size: 24px !important;
  border: 1px solid transparent !important;
  border-radius: 50px 50px 50px 50px;
  padding: 2px 11px !important;
  width: 13%;
  background: #0069B8 !important;
  color: #fff !important;
}

.error-label {
  padding-top: 10px;
  color: #6EC1E4 !important;
  font-size: 20px !important;
}

.ms-login {
  line-height: 40px;
  font-size: 24px !important;
  border: 1px solid transparent !important;
  border-radius: 50px 50px 50px 50px;
  padding: 2px 11px !important;
  width: 10%;
  background: #0069B8 !important;
  color: #fff !important;
}

.login-button:hover, mgt-login .login-button:hover{
  background: #0069B8 !important;
  color: #fff !important;
  font-size: 18px;
}

.disabled {
  pointer-events: none;
}

