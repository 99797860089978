.notification-bar:before {
  content: '';
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  color: transparent;
  border: 10px solid black;
  border-color: transparent transparent #366192;
  margin-top: -20px;
  margin-left: 360px;
}

.notification-bar {
  display: block;
  box-shadow: 2px 3px 17px -3px #8888888c;
  border-radius: 5px;
  perspective: 1px;
  z-index: 5;
  position: absolute;
  width: 30%;
  border: 0.5px solid #8080803d;
  min-height: 100px;
  top: 77%;
  left: 49%;
  background: #ffffff;
}

.notifications-label {
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  letter-spacing: 1px;
  padding: 15px;
  height: 55px;
  color: white;
  background: #366192;
  width: 100%;
}

.notification-item {
  border-bottom: 0.5px solid #f9f6f6;
  cursor: pointer;
}

.notification-item-type {
  color: #544343;
  padding-left: 15px;
  padding-top: 10px;
  font-size: 15px;
  font-weight: 600;
}

.notification-item-message {
  padding-left: 15px;
  font-size: 14px;
  font-weight: 400;
  color: #366192;
  max-width: 70%;
}

.notification-item-date {
  font-size: 14px;
  font-weight: 500;
  padding-left: 10px;
  color: grey;
}

.notification-button-container {
  padding-top: 10px;
  /*padding-bottom: 10px;*/
}

.notification-btn {
  /*margin-left: 30%;*/
  background: rgba(19, 39, 53, 0.15);
  width: 150px;
  cursor: pointer;
  font-size: 15px;
  line-height: 2;
  letter-spacing: 0.03rem;
  white-space: nowrap;
  margin-bottom: 0;
  width: 100%;
  margin: 0;
  padding: 2px;

  &.notification-btn-square {
    /*border-radius: 5px;*/
  }

  &.notification-btn-primary {
    background-color: #366192;
    color: white;
    &:hover {
      background: #366192;
    }
  }
}

.notification-item-dos {
  padding-left: 15px;
  font-size: 14px;
  font-weight: 400;
  color: #366192;
  padding-bottom: 10px;
  max-width: 70%;
}

.dot {
  height: 10px;
  width: 10px;
  background-color: #366192;
  border-radius: 50%;
  display: inline-block;
  float: right;
  margin-right: 15px;
  margin-top: 20px;
}